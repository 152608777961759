:root {
  --secondary: #2242b4;
  --main: #050038;
}

@import url("typography.css");
.inter {
  font-family: Inter;
}
.satoshi {
  font-family: Satoshi;
}
.bolder {
  font-family: SatoshiBold !important;
  font-weight: 900;
}
.bold {
  font-family: SatoshiBold !important;
  font-weight: 700;
}
.karla {
  font-family: Karla;
}
.kum {
  font-family: Kum;
}
.manrope {
  font-family: Manrope;
}

.hero {
  background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    url("./assets/new-heroimg.png");
  background-size: cover;
  background-position: center;
}
.hero2 {
  background: url("./assets/video-circle.svg");
  background-position: center;
  background-repeat: no-repeat;
}
.login {
  background: url("./assets/designers2.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}
.login2 {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}
.signup-talent {
  background: url("./assets/designers2.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px var(--secondary);
  border-radius: 10px;
}
::-webkit-scrollbar-thumb {
  background: #00000066;
  border-radius: 10px;
}
::-webkit-scrollbar-thumb:hover {
  background: var(--main);
}
.noScroll::-webkit-scrollbar {
  width: 0px;
  height: 0px;
}
