@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  cursor: url("./assets/icons8-cursor.svg");
}
h1,
h2,
h3,
h4,
h5,
h6,
p {
  padding: 0;
  margin: 0;
  margin-inline-start: 0;
  margin-inline-end: 0;
  margin-block-start: 0;
  margin-block-end: 0;
}

/* Transition animation for modal */
.modal-enter {
  opacity: 0;
  transform: translateY(-100%);
}

.modal-enter-active {
  opacity: 1;
  transform: translateY(0);
  transition: opacity 300ms, transform 300ms;
}

.modal-exit {
  opacity: 1;
  transform: translateY(0);
}

.modal-exit-active {
  opacity: 0;
  transform: translateY(-100%);
  transition: opacity 300ms, transform 300ms;
}
